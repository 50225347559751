import { DateTime } from "luxon";
import { getLang } from "@/services/http-service";
import store from "../store/index";

// console.log(date.zoneName); // get zone name
// date.setZone("zone string") // set zone
// dt.set({ year: 2016, month: 3 }) // set time

const ClebexCalendar = {
  expanded: true,
  timezone: DateTime.now().zoneName,
  today() {
    return this.setToday();
  },
  numOfDaysInAWeek: 7,
  manuallySelectedMonth: null,
  manuallySelectedYear: null,
  selectedMonth() {
    if (this.manuallySelectedMonth !== null) {
      return this.manuallySelectedMonth;
    }
    return this.selectedDate ? this.selectedDate.month : this.today().month;
  },
  selectedYear() {
    if (this.manuallySelectedYear !== null) {
      return this.manuallySelectedYear;
    }
    return this.selectedDate ? this.selectedDate.year : this.today().year;
  },
  occupiedDates: null,
  selectedDate: null,
  disablePreviousDays: false,
  cyclicYear: false,
  doubleDigit(di) {
    const stringDigit = di.toString();
    return stringDigit.length === 1 ? `0${stringDigit}` : stringDigit;
  },
  currentMonth() {
    return this.today().set({
      month: this.selectedMonth(),
      year: this.selectedYear(),
    });
  },
  setToday(date) {
    let today;
    if (date) {
      today = date;
    } else {
      today = DateTime.now();
    }
    if (this.timezone) {
      return today.setZone(this.timezone);
    }
    return today;
  },
  setSelectedMonth(number) {
    this.manuallySelectedMonth = number;
  },
  setSelectedYear(number) {
    this.manuallySelectedYear = number;
  },
  month() {
    return this.currentMonth().month; // mjesec u formatu broja
  },
  year() {
    return this.currentMonth().year;
  },
  numberOfDaysInAMonth() {
    return this.currentMonth().daysInMonth; // broj
  },
  /**
   * Returns number of day in the week
   * 0: sunday
   * 1: monday
   * 2: tuesday
   * 3: wednesday
   * 4: thursday
   * 5: friday
   * 6: saturday
   * @returns {number}
   */
  dayOfTheWeek() {
    return this.today().weekday;
  },
  /**
   * Returns number of day in a month 1 - 31
   * @returns {number}
   */
  dayOfTheMonth() {
    return this.today().day;
  },
  /**
   * Divides a current month into 7 and flooring it
   * to show how many full 7 days cycles are in the month.
   * NOTE: This is not a true week of the year, just num of
   * days in a month divided by 7.
   * @returns {number}
   */
  numberOfFullWeeks() {
    return Math.floor(this.numberOfDaysInAMonth() / 7);
  },
  /**
   * Returns a number which shows in which 7 day cycle (week)
   * is current day.
   * NOTE: This is not a true week of the year, just num of
   * days in a month divided by 7.
   * @returns {number}
   */
  currentDayIsInWeek() {
    return Math.ceil(this.dayOfTheMonth() / 7);
  },
  /**
   * Returns a position of the day in the true week.
   * So wee could calculate how to draw calendar and where
   * to put days that come before and after.
   * @returns {number}
   */
  positionInWeek() {
    return this.dayOfTheMonth() % 7;
  },
  currentMonthIsEqualToSelected() {
    return this.today().month === this.currentMonth().month;
  },
  /**
   * Function that creates day labels S, M, T etc.
   * Not every combination of shown days will have
   * full week days in single week view.
   * @returns {[]}
   */
  labelsOfDaysSingleWeek() {
    /**
     * Uzeti u obzir kraj mjeseca za daysAfter i daysBefore
     * ako poslednja sedmica u mjesecu ima npr 2 dana onda ofsetovati pocetak za 5
     *
     * Provjeriti da li je trenutno selektovani mjesec jednak danasnjem mjesecu
     */
    let days = [];
    let position = 1;
    let dayOfTheMonth = 1;

    if (this.currentMonthIsEqualToSelected()) {
      position = this.positionInWeek();
      dayOfTheMonth = this.dayOfTheMonth() + 1;
    }

    if (dayOfTheMonth + 7 > this.numberOfDaysInAMonth()) {
      position += dayOfTheMonth + 7 - this.numberOfDaysInAMonth() + 3;
    }

    const firstIndex = position - 1;
    // days[firstIndex] = this.currentMonth().format("dd");
    days[firstIndex] = this.currentMonth()
      .set({ day: dayOfTheMonth })
      .toFormat("ccc");

    const daysAfter = 7 - position;
    const daysBefore = 7 - daysAfter - 1; // 7 days - remaining days - current day
    if (daysBefore) {
      for (let i = 1; i <= daysBefore; i++) {
        days[firstIndex - i] = this.currentMonth()
          .set({ day: dayOfTheMonth })
          .minus({ days: i })
          .toFormat("ccc");
      }
    }

    if (daysAfter) {
      for (let i = 1; i <= daysAfter; i++) {
        days[firstIndex + i] = this.currentMonth()
          .set({ day: dayOfTheMonth })
          .plus({ days: i })
          .toFormat("ccc");
      }
    }

    return days;
  },
  /**
   * Returns array of days to be shown in a single week view.
   * @returns {[]}
   */
  numbersOfDaysToDisplaySingleWeek() {
    let days = [];
    let weekNumber = 1;
    let dayOfTheMonth = 1;

    if (this.currentMonthIsEqualToSelected()) {
      weekNumber = this.currentDayIsInWeek();
      dayOfTheMonth = this.dayOfTheMonth();
    }

    // Single week start is always at the beginning
    let numbersStart = weekNumber * 7 - 6;
    let numbersEnd = weekNumber * 7;

    if (numbersEnd > this.numberOfDaysInAMonth()) {
      numbersEnd = this.numberOfDaysInAMonth();
    }

    if (numbersEnd - numbersStart < 6) {
      numbersStart -= 6 - (numbersEnd - numbersStart);
    }

    for (let i = numbersStart + 1; i <= numbersEnd + 1; i++) {
      // MM-dd-yyyy  ${this.doubleDigit(this.month() + 1)}-${this.doubleDigit(i)}-${this.year()}
      // let d = new Date(`${this.year()}, ${this.month() + 1}, ${i}`); old not working in safari
      let d = new Date(
        String(
          `${this.year()}-${this.doubleDigit(this.month())}-${this.doubleDigit(
            i
          )}`
        )
      );

      const obj = {
        dayNum: i,
        dateObj:
          dayOfTheMonth === i
            ? this.today()
            : DateTime.fromJSDate(d).setZone(this.timezone, {
                keepLocalTime: true,
              }),
        dateString: `${this.year()}-${this.doubleDigit(
          this.month()
        )}-${this.doubleDigit(i)}`,
      };

      if (
        this.disablePreviousDays &&
        DateTime.fromJSDate(d)
          .setZone(this.timezone)
          .startOf("day") < this.today().startOf("day")
      ) {
        obj.outOfRange = true;
      }

      days.push(obj);
    }

    return days;
  },
  labelsOfDaysWholeMonth() {
    const locale = getLang();
    let today = this.currentMonth();

    return [
      today.set({ weekday: 1 }).toFormat("ccc", { locale: locale }),
      today.set({ weekday: 2 }).toFormat("ccc", { locale: locale }),
      today.set({ weekday: 3 }).toFormat("ccc", { locale: locale }),
      today.set({ weekday: 4 }).toFormat("ccc", { locale: locale }),
      today.set({ weekday: 5 }).toFormat("ccc", { locale: locale }),
      today.set({ weekday: 6 }).toFormat("ccc", { locale: locale }),
      today.set({ weekday: 7 }).toFormat("ccc", { locale: locale }),
    ];
  },
  numbersOfDaysToDisplayWholeMonth() {
    let today = this.currentMonth();
    let days = [];
    let weekDayOfTheFirstDayInTheMonth = today.set({ day: 1 }).weekday;
    // dodati i uslov ako je januar da kupi od prethodne godine :(
    // i ako je decembar da kupi od sljedece godine
    // Add numbers from last month in front of this.month's days if not sunday
    if (weekDayOfTheFirstDayInTheMonth !== 1) {
      let previousMonth;
      let previousMonthNumberOfDays;
      let year = today.year;

      // if january take last month of previous year
      if (today.month === 1) {
        previousMonth = 12;
        previousMonthNumberOfDays = 31; // december has 31 days no need to calculate
        year = today.set({ year: today.year - 1 }).year;
      } else {
        previousMonth = today.set({ month: today.month - 1 });
        previousMonthNumberOfDays = previousMonth.daysInMonth;
      }

      const dayNum =
        previousMonthNumberOfDays - weekDayOfTheFirstDayInTheMonth + 2;
      const prevMonth =
        typeof previousMonth === "number" ? previousMonth : previousMonth.month;

      for (let i = dayNum; i <= previousMonthNumberOfDays; i++) {
        // MM-dd-yyyy  ${prevMonth + 1}-${this.doubleDigit(i)}-${this.year()}
        // let d = new Date(`${year}, ${prevMonth + 1}, ${i}`); old not working in safari

        const dateObj = DateTime.fromISO(
          `${year}-${this.doubleDigit(prevMonth)}-${this.doubleDigit(i)}`
        ).setZone(this.timezone, { keepLocalTime: true });

        days.push({
          dayNum: i,
          dateObj: dateObj,
          // dateString: `${year}, ${prevMonth + 1}, ${i}`,
          dateString: `${year}-${this.doubleDigit(
            prevMonth
          )}-${this.doubleDigit(i)}`,
          outOfRange: true,
        });
      }
    }

    // Add days of current month to array
    for (let i = 1; i <= this.numberOfDaysInAMonth(); i++) {
      const dateObj = DateTime.fromISO(
        `${this.year()}-${this.doubleDigit(this.month())}-${this.doubleDigit(
          i
        )}`
      ).setZone(this.timezone, { keepLocalTime: true });

      const formatedDayObject = dateObj.toFormat("yyyy-MM-dd");
      const obj = {
        dayNum: i,
        dateObj: dateObj,
        // dateString: `${this.year()}, ${this.month() + 1}, ${i}`
        dateString: `${this.year()}-${this.doubleDigit(
          this.month()
        )}-${this.doubleDigit(i)}`,
      };

      if (
        this.disablePreviousDays &&
        dateObj.startOf("day") < this.today().startOf("day")
      ) {
        obj.outOfRange = true;
      }

      if (this.occupiedDates && this.occupiedDates.length) {
        const index = i === 0 ? 0 : i - 1;
        if (
          this.occupiedDates &&
          this.occupiedDates[index] &&
          this.occupiedDates[index].date === formatedDayObject
        ) {
          let occupancy = "low";
          if (
            this.occupiedDates[index].occupancy_percentage >=
            this.getBusyPercentage()
          )
            occupancy = "high";
          else if (
            this.occupiedDates[index].occupancy_percentage >
              this.getPartiallyBusyPercentage() &&
            this.occupiedDates[index].occupancy_percentage <
              this.getBusyPercentage()
          )
            occupancy = "moderate";
          if (this.occupiedDates[index].occupancy) {
            occupancy += " has-occupancy";
          }
          obj.occupancy_percentage = occupancy;
        }
      }
      days.push(obj);
    }

    // if last day of the month is not sunday add days from next month
    if (today.set({ day: this.numberOfDaysInAMonth() }).weekday !== 7) {
      // return week day of the last day of the month
      // so we could properly add missing days of the next month
      let weekDayOfTheLastDayInTheMonth = today.set({
        day: this.numberOfDaysInAMonth(),
      }).weekday;

      if (weekDayOfTheLastDayInTheMonth !== 7) {
        // if not sunday add numbers
        let year = this.year();
        let nextMonth;

        if (today.month === 12) {
          // if december take next year's january
          year = today.set({ year: today.year + 1 }).year; // next year
          nextMonth = 1; // january
        } else {
          nextMonth = today.set({ month: today.month + 1 }).month;
        }

        for (let i = 1; i <= 7 - weekDayOfTheLastDayInTheMonth; i++) {
          // MM-dd-yyyy  ${this.doubleDigit(this.month() + 1)}-${this.doubleDigit(i)}-${this.year()}
          // let d = new Date(`${year}, ${nextMonth + 1}, ${i}`); old not working in safari
          const dateObj = DateTime.fromISO(
            `${year}-${this.doubleDigit(nextMonth)}-${this.doubleDigit(i)}`
          ).setZone(this.timezone, { keepLocalTime: true });
          days.push({
            dayNum: i,
            dateObj: dateObj,
            // dateString: `${year}, ${nextMonth + 1}, ${i}`,
            dateString: `${year}-${this.doubleDigit(
              nextMonth
            )}-${this.doubleDigit(i)}`,
            outOfRange: true,
          });
        }
      }
    }

    return days;
  },
  isToday(day) {
    if (this.today()) {
      return day === this.today().toFormat("yyyy-MM-dd") && !day.outOfRange;
    }
    return false;
  },
  isSelected(day) {
    if (this.selectedDate) {
      return (
        day.dateObj.startOf("day").ts == this.selectedDate.startOf("day").ts
      );
    }
    return false;
  },
  selectDate(date) {
    this.selectedDate = date;
  },
  setExpanded() {
    this.expanded = !this.expanded;
  },
  nextMonth() {
    const month = this.manuallySelectedMonth || this.selectedMonth();
    const year = this.manuallySelectedYear || this.selectedYear();
    if (month === 12) {
      this.setSelectedMonth(1);
      if (!this.cyclicYear) {
        this.setSelectedYear(year + 1);
      }
    } else {
      this.setSelectedMonth(month + 1);
    }
  },
  prevMonth() {
    const month = this.manuallySelectedMonth || this.selectedMonth();
    const year = this.manuallySelectedYear || this.selectedYear();
    if (month === 1) {
      this.setSelectedMonth(12);
      if (!this.cyclicYear) {
        this.setSelectedYear(year - 1);
      }
    } else {
      this.setSelectedMonth(month - 1);
    }
  },
  parse(format) {
    return this.currentMonth().toFormat(format);
  },
  parsedDateMonthYear() {
    return this.currentMonth().toFormat("MMMM yyyy");
  },
  parsedDateMonth() {
    const locale = getLang();
    return this.currentMonth().toFormat("MMMM", { locale: locale });
  },
  parsedDateYear() {
    return this.currentMonth().toFormat("yyyy");
  },
  getPartiallyBusyPercentage() {
    let partialBusyPercentage = 60;
    let preferences = store._state.data.companyPreferences.preferences;

    let partiallyBusyPreference = preferences.find(
      (el) => el.preference === "PARTIALLY_BUSY_PERCENTAGE"
    );

    if (
      partiallyBusyPreference &&
      partiallyBusyPreference.preference_values &&
      partiallyBusyPreference.preference_values.length
    ) {
      partialBusyPercentage =
        partiallyBusyPreference.preference_values[0].value;
    }

    return partialBusyPercentage;
  },
  getBusyPercentage() {
    let busyPercentage = 90;
    let preferences = store._state.data.companyPreferences.preferences;

    let busyPreference = preferences.find(
      (el) => el.preference === "BUSY_PERCENTAGE"
    );

    if (
      busyPreference &&
      busyPreference.preference_values &&
      busyPreference.preference_values.length
    ) {
      busyPercentage = busyPreference.preference_values[0].value;
    }

    return busyPercentage;
  },
};

export default ClebexCalendar;
